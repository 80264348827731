* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body
{
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar{
  width: 10px;
}

::-webkit-scrollbar-track-piece{
  background-color: #FFF;
}

::-webkit-scrollbar-thumb{
  background-color: #CBCBCB;
  outline: 2px solid #FFF;
  outline-offset: -2px;
  border: .1px solid #B7B7B7;
}

::-webkit-scrollbar-thumb:hover{
  background-color: #909090;
}