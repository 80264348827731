.innerSquare {
    background-color: white;
    border-radius: 0px;
    height: 100%;
    width: 100%;
}

.rainbowGradient {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 5px;


    box-shadow: inset 0 0 12px 12px white, inset 0 0 3px 2px white;
    background: linear-gradient(to right, grey,grey);
}

.rainbowGradientRound {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 5px;
    width: 99%;
    height: 12vh;

    box-shadow: inset 0 0 12px 12px white, inset 0 0 3px 2px white;
    background: linear-gradient(to right, grey,grey);
}